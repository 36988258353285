<template>
  <div class="container-fluid">
  <h1>Historical Project Para Data</h1>
  <h4>The data displayed is from Project Para prior to 2022.</h4>
  </div>
<div>
    <HistoricalCertificationTable />
</div>
</template>

<script>
import {ref} from "vue";
import HistoricalCertificationTable from "@/components/HistoricalCertifications/HistoricalCertificationTable.vue";
export default {
	name: "HistoricalCertSearch",
	components: {HistoricalCertificationTable},
	setup() {

	}
}
</script>

<style scoped>

</style>