import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const genPdf = (htmlVal, fileName) => {
    let pdf = new jsPDF({
        orientation: 'l',
        unit: 'px',
        format: 'a4',
        hotfixes: ['px_scaling'],
    });

    html2canvas(htmlVal, {
        width: pdf.internal.pageSize.getWidth(), height: pdf.internal.pageSize.getHeight()
    }).then((canvas) => {
        const img = canvas.toDataURL("image/png");
        pdf.addImage(img, "PNG", 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
        pdf.save(fileName);
    });
}