<template>
  <form @submit.prevent="getCertList">

    <div class="row">
      <div class="col">
        <label>Search</label>
        <input class="form-control" v-model="searchParam">
      </div>
      <div class="col"></div>

      <div class="col"></div>
      <div class="col" style="text-align: left;">
        <button type="submit" class="button btn-primary" @click="getCertList">Search</button>
      </div>
    </div>
  </form>

  <MDBDatatable
      :dataset="historicalCertList"
      pagination
      hover
      striped
      sm
      :maxHeight="635"
      exportToCSV
      :entries="25"
      @render="setActions"
  />
</template>

<script>
import {computed, onBeforeMount, ref} from "vue";
import axios from "axios";
import {MDBDatatable} from "mdb-vue-ui-kit";
import {historicalCertByKeyword} from "@/Helpers/ApiCalls/ReportAPICalls";
import {genPdf} from "@/components/Reporting/Certifications/GenPdf";
import {formatCertificationInDatatable} from "@/Helpers/DatatableFieldPrepFunctions";

export default {
  name: "HistoricalCertificationTable",
  components: {MDBDatatable},
  setup() {
    const selectedCertId = ref(-1);
    const historicalCertList = ref({
      columns: [
        {label: "First Name", field: "firstName"},
        {label: "Last Name", field: "lastName"},
        {label: "District Name", field: "districtName"},
        {label: "Creation Date", field: "creationDate"},
        {label: "Special Ed Cert", field: "specialEdCert"},
        {label: "Title 1 Cert", field: "title1Cert"},
        {label: "Early Childhood Cert", field: "earlyChildhoodCert"},
        {label: "Roles Passed", field: "rolesPassed"},
        {label: "Org Passed", field: "orgPassed"},
        {label: "Instr Passed", field: "instrPassed"},
        {label: "Comm Passed", field: "commPassed"},
        {label: "Ethics Passed", field: "ethicsPassed"},
        {label: "Sped Passed", field: "spedPassed"},
        {label: "Behavior Passed", field: "behaviorPassed"},
        {label: "Obs Passed", field: "obsPassed"},
        {label: "Autism Passed", field: "autismPassed"},
        {label: "Job Coach Passed", field: "jobCoachPassed"},
        {label: "Early Childhood Passed", field: "earlyChildhoodPassed"},
        {label: "Reading Passed", field: "readingPassed"},
        {label: "Writing Passed", field: "writingPassed"},
        {label: "Math Passed", field: "mathPassed"},


      ],
      rows: []
    });

    const searchParam = ref("");

    async function getCertList() {
      await axios.get(historicalCertByKeyword(searchParam.value))
          .then((result) => {
            historicalCertList.value.rows = setCertListDisplay(result.data);
          })
    }

    function setCertListDisplay(certList) {
      let tmp_arr = [];
      for (let i = 0; i < certList.length; i++) {
        let tmp_date = new Date(certList[i]['creationDate']);
        let tmp_date_display = tmp_date.toLocaleDateString('en-us');
        let tmp_cert = {
          idCertification: certList[i]['idCertification'],
          idDistrict: certList[i]['idDistrict'],
          districtName: certList[i]['districtName'],
          firstName: certList[i]['firstName'],
          lastName: certList[i]['lastName'],
          creationDate: `<span style="display: none">${certList[i]['creationDate']}</span><span>${tmp_date_display}</span>`,
          rolesPassed: certList[i]['rolesPassed'] ? "Yes" : "No",
          orgPassed: certList[i]['orgPassed'] ? "Yes" : "No",
          instrPassed: certList[i]['instrPassed'] ? "Yes" : "No",
          commPassed: certList[i]['commPassed'] ? "Yes" : "No",
          ethicsPassed: certList[i]['ethicsPassed'] ? "Yes" : "No",
          spedPassed: certList[i]['spedPassed'] ? "Yes" : "No",
          behaviorPassed: certList[i]['behaviorPassed'] ? "Yes" : "No",
          obsPassed: certList[i]['obsPassed'] ? "Yes" : "No",
          autismPassed: certList[i]['autismPassed'] ? "Yes" : "No",
          jobCoachPassed: certList[i]['jobCoachPassed'] ? "Yes" : "No",
          earlyChildhoodPassed: certList[i]['earlyChildhoodPassed'] ? "Yes" : "No",
          readingPassed: certList[i]['readingPassed'] ? "Yes" : "No",
          writingPassed: certList[i]['writingPassed'] ? "Yes" : "No",
          mathPassed: certList[i]['mathPassed'] ? "Yes" : "No",
          // specialEdCert: certList[i]['specialEdCert'] ? "Yes" : "No",
          specialEdCert: formatCertificationInDatatable(
              "Special Education Certification",
              certList[i]["specialEdCert"],
              "special-ed-cert",
              certList[i]["idCertification"],
          ),
          // title1Cert: certList[i]['title1Cert'] ? "Yes" : "No",
          title1Cert: formatCertificationInDatatable(
              "Title I Certification",
              certList[i]["title1Cert"],
              "title-1-cert",
              certList[i]["idCertification"],
          ),
          // earlyChildhoodCert: certList[i]['earlyChildhoodCert'] ? "Yes" : "No",
          earlyChildhoodCert: formatCertificationInDatatable(
              "Early Childhood Certification",
              certList[i]["earlyChildhoodCert"],
              "early-childhood-cert",
              certList[i]["idCertification"],
          )
        }
        tmp_arr.push(tmp_cert);
      }
      return tmp_arr;
    }

    const setActions = () => {
      const date_options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      }
      let sped_cert_elements = document.getElementsByClassName("special-ed-cert");
      Array.prototype.forEach.call(sped_cert_elements, function (btn) {
        if (btn.getAttribute("click-listener") !== "true") {
          btn.addEventListener("click", () => {
            selectedCertId.value = parseInt(btn.attributes['data-mdb-id'].value);
            downloadPdf(
                `${selectedCertUser.value.firstName} ${selectedCertUser.value.lastName}`,
                btn.attributes['data-mdb-cert-name'].value,
                new Date().toLocaleDateString('en-us', date_options),
            )
          });
          btn.setAttribute("click-listener", "true");
        }
      });

      let title_1_cert_elements = document.getElementsByClassName("title-1-cert");
      Array.prototype.forEach.call(title_1_cert_elements, function (btn) {
        if (btn.getAttribute("click-listener") !== "true") {
          btn.addEventListener("click", () => {
            selectedCertId.value = parseInt(btn.attributes['data-mdb-id'].value);
            downloadPdf(
                `${selectedCertUser.value.firstName} ${selectedCertUser.value.lastName}`,
                btn.attributes['data-mdb-cert-name'].value,
                new Date().toLocaleDateString('en-us', date_options),
            )
          });
          btn.setAttribute("click-listener", "true");
        }
      })

      let early_childhood_cert_elements = document.getElementsByClassName("early-childhood-cert");
      Array.prototype.forEach.call(early_childhood_cert_elements, function (btn) {
        if (btn.getAttribute("click-listener") !== "true") {
          btn.addEventListener("click", () => {
            selectedCertId.value = parseInt(btn.attributes['data-mdb-id'].value);
            downloadPdf(
                `${selectedCertUser.value.firstName} ${selectedCertUser.value.lastName}`,
                btn.attributes['data-mdb-cert-name'].value,
                new Date().toLocaleDateString('en-us', date_options),
            )
          });
          btn.setAttribute("click-listener", "true");
        }
      })
    }

    function downloadPdf(userName, certName, date) {
      console.log("HI")
      let htmlString = `<div class="cert-body row" style="background-image: url('/images/background_templates/certificate_bg.png'); background-size: 1140px 790px; width: 1185px; height: 795px;background-repeat: no-repeat;padding: 20px;text-align: center;align-items: center; position: relative;">
      <div>
        <br/><br/>
        <span style="font-size: 30px; font-family: 'DejaVu Sans',serif; color: black;">This is to acknowledge that</span>
        <br><br>
        <span style="font-size: 30px; font-family: 'DejaVu Sans',serif; color: black;"><b>${userName}</b></span><br/><br/>
        <span style="font-size: 30px; font-family: 'DejaVu Sans',serif; color: black;">has satisfactorily completed:</span> <br/><br/>
        <span style="font-size: 30px; font-family: 'DejaVu Sans',serif; color: black; word-wrap: break-word; font-weight: bold;"><i>${certName}.</span></i> <br/><br/>
        <div class="col-sm-4 mt-4" style="margin-left: 65px; position: absolute;">
          <span style="font-size: 25px; font-family: 'DejaVu Sans',serif; color: black;">Verification Date:</span>
          <p ><span style="font-size: 25px; font-family: 'DejaVu Sans',serif; color: black; border-bottom: 1px solid black;">${date}</span></p>
        </div>
      </div>
    </div>`;
      let htmlObj = document.createElement("div");
      htmlObj.innerHTML = htmlString;
      document.body.appendChild(htmlObj)
      const fileName = `${userName.replace(" ", "_")}_${certName.replace(" ", "_")}`
      genPdf(htmlObj, fileName);
      htmlObj.remove();
    }

    const selectedCertUser = computed(() => {
      return historicalCertList.value.rows.filter((c) => c.idCertification === selectedCertId.value)[0];
    })

    return {
      historicalCertList,
      searchParam,
      setActions,
      getCertList,
    }
  }
}
</script>

<style scoped>

</style>